import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemIcon,
  Popover,
  Typography,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import InputIcon from '@material-ui/icons/Input';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import SmsIcon from '@material-ui/icons/Sms';
import ABTestIcon from 'components/Icons/ABTestIcon';
import LogicIcon from 'components/Icons/LogicIcon';
import MultipleChoiceIcon from 'components/Icons/MultipleChoiceIcon';
import NluIcon from 'components/Icons/NluIcon';
import { useFlow } from 'contexts/Flow/flowContext';
import {
  MetaBlockType,
  MetadataBlock,
  // DynamicBlockType,
  MetadataBlocks,
} from 'contexts/Flow/types';
import EIntentType from 'enums/EIntentType';
import { DataFlow, DataForm, InputOutputObject, Output } from 'models/DataFlow';
import useTranslator from 'utils/hooks/Translator';
import { useStyles } from './styles';
import './styles.css';
// import HttpIcon from '@material-ui/icons/Http';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { markSelected } from 'components/Flow/utils/NodeContainer';
import AddBlockToFlowController from 'controllers/library/AddBlockToFlowController';
import GetOffsetPositionFlowController from 'controllers/library/GetOffsetPositionFlowController';
import DataFormModel from 'models/DataFormModel';
import { getTranslationKeys } from 'utils/i18n/types';
import data from './metablocks.json';
import { ModalOptionsItem } from './ModalOptionsItem';

const environment = process.env.REACT_APP_ENV;

export default function ModalOptions(props: { anchorEl: any }) {
  const { state, dispatch, mountNodeContent, goToNodePosition } = useFlow();
  const classes = useStyles();
  const { getTranslation } = useTranslator();

  const intentsList = [
    {
      name: getTranslation('intents.sendMessage'),
      intentType: EIntentType.SendMessage,
      icon: <SmsIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.simpleQuestion'),
      intentType: EIntentType.SimpleQuestion,
      icon: <InputIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.optionsBlock'),
      intentType: EIntentType.OptionsBlock,
      icon: <MultipleChoiceIcon />,
    },
    {
      name: getTranslation('intents.carousel'),
      intentType: EIntentType.Carousel,
      icon: <RecentActorsIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.sendDocument'),
      intentType: EIntentType.SendDocument,
      icon: <DescriptionIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.nlu'),
      intentType: EIntentType.NLU,
      icon: <NluIcon />,
    },
    {
      name: getTranslation('intents.logic'),
      intentType: EIntentType.Logic,
      icon: <LogicIcon size="20" />,
    },
    {
      name: getTranslation('intents.newGroup'),
      intentType: EIntentType.NewGroup,
      icon: <ReplyAllIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.multipleChoice'),
      intentType: EIntentType.MultipleChoiceParent,
      icon: <DeviceHubIcon className={classes.listSvg} />,
    },
    {
      name: getTranslation('intents.abTest'),
      intentType: EIntentType.ABTest,
      icon: <ABTestIcon size="20" />,
    },
    {
      name: getTranslation('intents.toHtml'),
      intentType: EIntentType.OpenHTML,
      icon: <OpenInNewIcon className={classes.listSvg} />,
    },
  ];

  const [blocksGroupedByCategory, setBlocksGroupedByCategory] = useState<{
    [category: string]: MetadataBlock[];
  }>({});

  useEffect(() => {
    const grouped = groupBlocksByCategory(data as MetadataBlocks);
    setBlocksGroupedByCategory(grouped);
  }, []);

  // useEffect(() => {
  //   async function fetchMetaBlocks() {
  //     const response = await fetch(
  //       'https://cloud-metablocks.web.fintalk.io/metablocks.json'
  //     );
  //     if (response.ok) {
  //       const metaBlocks = await response.json();
  //       const grouped = groupBlocksByCategory(metaBlocks as MetadataBlocks);
  //       setBlocksGroupedByCategory(grouped);
  //     }
  //   }

  //   fetchMetaBlocks();
  // }, []);

  function groupBlocksByCategory(data: MetadataBlocks): {
    [category: string]: MetadataBlock[];
  } {
    const grouped: { [category: string]: MetadataBlock[] } = {};

    data.blocks.forEach((block) => {
      if (!grouped[block.category]) {
        grouped[block.category] = [];
      }

      grouped[block.category].push(block);
    });

    return grouped;
  }

  const getBlockName: { [key in MetaBlockType]: string } = {
    ABTest: 'teste-ab',
    Api: 'api',
    Carousel: 'carrossel',
    Entry: 'entrada',
    Question: 'pergunta',
    Message: 'mensagem',
    Multiple: 'multipla',
    Logic: 'logica',
    ToAnotherBlock: 'pular',
    GetDocument: 'documento',
    ValidateCPF: 'valida-cpf',
    ValidateCNPJ: 'valida-cnpj',
    Notification: 'notifica',
    Conditional: 'condicional',
    '123AI': '123AI',
    SimpleCarousel: 'carrossel-simples',
    Advanced: 'avançado',
    Bubble: 'bubble',
  };

  function handleCreateIntent(intentType: EIntentType) {
    const payload =
      intentType === EIntentType.MultipleChoiceParent
        ? ''
        : '// #PARSER#CONNECTOR#Saída#\n';
    const xml =
      intentType === EIntentType.MultipleChoiceParent
        ? ''
        : '<xml xmlns="https://developers.google.com/blockly/xml"><block type="connector" id="cAsx5InyFQ]_.i,bM.[~" x="150" y="150"><field name="TEXT">Saída</field></block></xml>';

    const dataForm: DataForm = {
      groupId: state.idGroup || '',
      name: '',
      description: '',
      intentType,
      outputs: [],
      dataBlockly: {
        lastVersion: 1,
        payload,
        xml,
        compiledPayload: '',
      },
      listType: 1,
    };

    dispatch({ type: 'openModalForms', data: { dataForm } });
    dispatch({ type: 'closeModalIntentType' });
  }

  const getDataFormOutputsFromType: { [key in MetaBlockType]: Output[] } = {
    Question: [{ title: 'Sucesso' }],
    Message: [{ title: 'Saída' }],
    ABTest: [{ title: 'Saída' }],
    Entry: [{ title: 'Saída' }],
    Advanced: [{ title: 'Saída' }],
    Logic: [{ title: 'Saída' }],
    ToAnotherBlock: [{ title: 'Saída' }],
    GetDocument: [{ title: 'Saída' }],
    Multiple: [{ title: 'Saída' }, { title: 'Saída2' }],
    SimpleCarousel: [{ title: 'Saída' }, { title: 'Saída2' }],
    Carousel: [{ title: 'Saída' }, { title: 'Saída2' }],
    ValidateCPF: [{ title: 'Saída' }],
    Notification: [{ title: 'Saída' }],
    Conditional: [{ title: 'Saída' }],
    ValidateCNPJ: [{ title: 'Saída' }],
    Api: [
      {
        title: 'Sucesso',
        statusCode: '200',
        ifCondition: 'resposta.statusCode == 200',
      },
      {
        title: 'Requisição Ruim',
        statusCode: '400',
        ifCondition: 'resposta.statusCode == 400',
      },
      {
        title: 'Erro Interno',
        statusCode: '500',
        ifCondition: 'resposta.statusCode == 500',
      },
      {
        title: 'Outros',
      },
    ],
    '123AI': [{ title: 'Saída' }],
    Bubble: [],
  };

  const getOutputsFromType: { [key in MetaBlockType]: InputOutputObject } = {
    Question: { output_1: { connections: [] } },
    Message: { output_1: { connections: [] } },
    ABTest: { output_1: { connections: [] } },
    Entry: { output_1: { connections: [] } },
    Advanced: { output_1: { connections: [] } },
    Logic: { output_1: { connections: [] } },
    ToAnotherBlock: {},
    GetDocument: { output_1: { connections: [] } },

    ValidateCPF: { output_1: { connections: [] } },
    Notification: { output_1: { connections: [] } },
    Conditional: { output_1: { connections: [] } },
    ValidateCNPJ: { output_1: { connections: [] } },
    '123AI': { output_1: { connections: [] } },
    Multiple: { output_1: { connections: [] }, output_2: { connections: [] } },
    SimpleCarousel: {
      output_1: { connections: [] },
      output_2: { connections: [] },
    },
    Carousel: { output_1: { connections: [] }, output_2: { connections: [] } },
    Api: {
      output_1: { connections: [] },
      output_2: { connections: [] },
      output_3: { connections: [] },
      output_4: { connections: [] },
    },
    Bubble: {},
  };

  const handleSendToFlow = async (block: MetadataBlock) => {
    const { editorFlow } = state;
    if (!editorFlow) return;

    const offsetPosition = new GetOffsetPositionFlowController(
      editorFlow
    ).getMiddleCanvasPosition();
    const drawflowCurrent = { ...editorFlow.drawflow };
    const controller = new AddBlockToFlowController(
      drawflowCurrent,
      state.idGroup || '',
      offsetPosition
    );
    const nextId = controller.getNextNodeId();

    const name = `${getBlockName[block.type]}-${nextId}`;
    const data = new DataFormModel({
      metadata: block,
      name,
      description: block.description,
      groupId: state.idGroup || 'principal',
      carouselItems: [],
      sendUser: block.type !== 'Question' ? { message: '' } : undefined,
      inputs:
        (block.usesOutputVar && block.type === 'Question') ||
        block.type === 'Entry'
          ? {
              variables: [
                {
                  name: '',
                  questions: [''],
                  required: false,
                  vartype: '@sys.any',
                  defaultValue: '',
                  isList: false,
                  value: '',
                },
              ],
            }
          : { variables: [], userSentences: [] },
      outputs: getDataFormOutputsFromType[block.type],
      intentType: EIntentType.MetadataBlock,
      block_id: String(nextId),
      dataBlockly: {
        compiledPayload: block.jsCode ? `${block.jsCode}` : '',
        lastVersion: 1,
        payload: block.jsCode ? `${block.jsCode}\n` : '',
        xml: '',
      },
      varValue: block.usesOutputVar ? 'resposta' : undefined,
    });

    await controller.addBlock({
      0: {
        class: 'dbclick',
        html: '',
        id: 0,
        name: 'dbclick',
        pos_x: offsetPosition.pos_x,
        pos_y: offsetPosition.pos_y,
        typenode: false,
        data: {
          ...data,
          inputs:
            data.isAwaitUserIput() || block.type === 'Advanced'
              ? {
                  variables: [
                    {
                      name: 'resposta',
                      questions: [''],
                      required: true,
                      vartype: '@sys.any',
                      defaultValue: '',
                      isList: false,
                      value: '$resposta',
                    },
                  ],
                }
              : block.type === 'Entry'
              ? {
                  variables: [],
                  userSentences: [],
                }
              : undefined,
        },
        outputs: getOutputsFromType[block.type],
        inputs: block.type !== 'Entry' ? { input_1: { connections: [] } } : {},
      },
    });
    const drawflow = controller.getDrawflow();
    editorFlow.clear();
    editorFlow.import(drawflow);
    const dataFlow: DataFlow = drawflow.drawflow.Home.data;
    mountNodeContent(dataFlow);
    goToNodePosition(nextId);
    markSelected(nextId.toString());
    handleCancel();
  };

  function handleCancel() {
    dispatch({ type: 'closeModalIntentType' });
  }

  function blockNameTranslated(name: string) {
    const translatedName = getTranslation(
      `intents.${name}` as getTranslationKeys
    );
    return translatedName.startsWith('intents') ? name : translatedName;
  }

  if (!state.openModalIntentType) return null;

  return (
    <Popover
      open={true}
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleCancel}
      PaperProps={{
        style: {
          marginLeft: '8px',
          background: 'rgb(255, 255, 255)',
        },
      }}
    >
      <List>
        {Object.entries(blocksGroupedByCategory).map((entry, index) => (
          <Accordion key={index} className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.accordionSummary}
            >
              <Typography>
                {getTranslation(entry[0] as getTranslationKeys)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionContent}>
              <List>
                {entry[1].map((block, index) => {
                  const hideBlock =
                    environment === 'production' && block.hideInPrd;

                  return (
                    !hideBlock && (
                      <ListItem
                        key={block.id || index}
                        button
                        onClick={() => handleSendToFlow(block)}
                      >
                        <ListItemIcon>
                          <Typography>
                            {blockNameTranslated(block.prettyName)}
                          </Typography>
                        </ListItemIcon>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.accordion}
          >
            <Typography>{getTranslation('legacyBlocks')}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <List>
              {intentsList.map((intent) => (
                <ModalOptionsItem
                  key={intent.name}
                  name={intent.name}
                  handleCreateIntent={() =>
                    handleCreateIntent(intent.intentType)
                  }
                  icon={intent.icon}
                  intentType={intent.intentType}
                />
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
    </Popover>
  );
}
