import { ApiEntries, BubbleEntries, MetadataBlock } from 'contexts/Flow/types';
import EIntentType from '../enums/EIntentType';
import EListType from '../enums/EListType';
import {
  DataBlockly,
  DataForm,
  IAttempts,
  IFlag,
  Input,
  IRocketChat,
  Linked,
  MessageType,
  Output,
  SendUser,
} from './DataFlow';

const waitUserInputBlocks = [
  'Question',
  'ValidateCPF',
  'ValidateCNPJ',
  'GetDocument',
];

const RightClickEventBlockedIntentTypes = [
  EIntentType.Others,
  EIntentType.Welcome,
  EIntentType.FlexBlockEntry,
  EIntentType.Fallback,
  EIntentType.Cancel,
];

const CloneEventBlockedIntentTypes = [
  EIntentType.FlexBlock,
  EIntentType.FlexBlockEntry,
];

class DataFormModel implements DataForm {
  idOutput?: string | undefined;
  parentId?: number;
  name: string = '';
  description: string = '';
  intentType: EIntentType = EIntentType.InitialFlow;
  groupId: string = '';
  sendUser?: SendUser | undefined;
  attempts?: IAttempts[] | undefined;
  inputs?: Input | undefined;
  outputs?: Output[] | undefined;
  linked?: Linked | undefined;
  dataBlockly?: DataBlockly | undefined;
  listType?: EListType = EListType.Horizontal;
  flag?: IFlag | undefined;
  rocketChat: IRocketChat = {
    rocketID: '',
    msgSuccess: '',
    msgFailure: '',
  };
  messageType?: MessageType = undefined;
  varValue?: string | undefined;
  saveVarValue?: boolean;
  entryName?: string;
  metadata?: MetadataBlock;
  block_id?: string | undefined;
  apiInfo?: ApiEntries | undefined;
  apiReturnCodeTreatment?: string | undefined;
  tags: string[] | undefined;
  audioVoice?: string | undefined;
  carouselItems?: Output[] | undefined;
  bubbleInfo?: BubbleEntries;

  constructor(data: DataForm) {
    if (!data.bubbleInfo) {
      data.bubbleInfo = {
        bubbleId: '',
        bubbleVersion: null
      }
    }

    Object.assign(this, data);
  }

  hasVariables() {
    return [
      EIntentType.SimpleQuestion,
      EIntentType.SendDocument,
      EIntentType.SendMessage,
    ].includes(this.intentType);
  }

  isOptionBlock() {
    return [EIntentType.OptionsBlock].includes(this.intentType);
  }

  isParent() {
    return [
      EIntentType.MultipleChoiceParent,
      EIntentType.CarouselParent,
    ].includes(this.intentType);
  }

  isChild() {
    return [
      EIntentType.MultipleChoiceChild,
      EIntentType.CarouselChild,
    ].includes(this.intentType);
  }

  isOthers() {
    return [EIntentType.Others].includes(this.intentType);
  }

  isMain() {
    return [EIntentType.Fallback, EIntentType.Cancel].includes(this.intentType);
  }

  isNewGroup() {
    return (
      this.intentType === EIntentType.NewGroup ||
      this.metadata?.type === 'ToAnotherBlock'
    );
  }

  isHtml() {
    return this.intentType === EIntentType.OpenHTML;
  }

  isAwaitUserIput() {
    return waitUserInputBlocks.includes(this.metadata!.type);
  }

  shouldBlockRightClickEvent() {
    return RightClickEventBlockedIntentTypes.includes(this.intentType);
  }

  shouldBlockClone() {
    return CloneEventBlockedIntentTypes.includes(this.intentType);
  }

  isFlexBlockExit() {
    return this.intentType === EIntentType.FlexBlockExit;
  }

  isFlexBlockEntry() {
    return this.intentType === EIntentType.FlexBlockEntry;
  }

  isFlexBlock() {
    return this.intentType === EIntentType.FlexBlock;
  }

  hasMultipleOutputs() {
    return [
      EIntentType.MultipleChoiceParent,
      EIntentType.CarouselParent,
      EIntentType.OptionsBlock,
      EIntentType.Carousel,
      EIntentType.NewGroup,
    ].includes(this.intentType);
  }

  getNameIntentType() {
    switch (this.intentType) {
      case EIntentType.MultipleChoiceParent:
        return 'Multipla ESCOLHA';
      case EIntentType.MultipleChoiceChild:
        return 'OPÇÃO';
      case EIntentType.CarouselParent:
        return 'CARROSSEL';
      default:
        return '';
    }
  }

  getOutputAmount(): number {
    return this.outputs?.length || 0;
  }

  getChildType() {
    return this.intentType === EIntentType.MultipleChoiceParent
      ? EIntentType.MultipleChoiceChild
      : EIntentType.CarouselChild;
  }

  getOutputs() {
    return this.outputs || [];
  }

  getAttempts() {
    return this.attempts || [];
  }

  getParentId() {
    return this.parentId || 0;
  }

  isOutputEqual(anoutherOutputs?: Output[]) {
    return JSON.stringify(this.outputs) === JSON.stringify(anoutherOutputs);
  }

  getNodeIdsOutputRemoved(prevOutputs?: Output[]) {
    const prevIds = prevOutputs?.map((output) => Number(output.outputid));
    const currentIds =
      this.outputs?.map((output) => Number(output.outputid)) || [];
    return prevIds?.filter((id) => !currentIds?.includes(id)) || [];
  }

  getOutputToUpdate() {
    return this.outputs?.filter((output) => output.outputid) || [];
  }

  getOutputToAdd() {
    return this.outputs?.filter((output) => !output.outputid) || [];
  }

  getMessage() {
    switch (this.intentType) {
      case EIntentType.QuestionsAndAnswers:
        const variables = this.inputs?.variables || [];
        const variable = !!variables.length ? variables[0] : undefined;
        if (variable) {
          return !!variable.questions.length ? variable.questions[0] : '';
        } else return '';

      case EIntentType.OpenHTML:
        const exhibitionString = !!this.sendUser?.url
          ? `Link: ${this.sendUser?.url}`
          : !!this.sendUser?.message
          ? `Inserido código HTML`
          : '';
        return exhibitionString;

      default:
        return this?.sendUser?.message || '';
    }
  }

  getMessageType() {
    let result = '';
    switch (this.messageType) {
      case 'text':
        result = 'Texto';
        break;
      case 'image':
        result = 'Imagem';
        break;
      case 'video':
        result = 'Vídeo';
        break;
      case 'tts':
        result = 'Áudio';
        break;
      case 'document':
        result = 'Documento';
        break;
      case 'email':
        result = 'E-mail';
        break;
      case 'anyNumber':
        result = 'Qualquer número';
        break;
      case 'url':
        result = 'Url';
        break;
      case 'phone':
        result = 'Telefone';
        break;
      case 'cpf':
        result = 'CPF';
        break;
      case 'cnpj':
        result = 'CNPJ';
        break;
      case 'cep':
        result = 'CEP';
        break;
      case 'uuid':
        result = 'UUID';
        break;
      case 'data':
        result = 'Data';
        break;
      case 'money':
        result = 'Monetário';
        break;
      case 'password':
        result = 'Senha';
        break;
      case 'lettersOnly':
        result = 'Somente Letras';
        break;
      case 'location':
        result = 'Localização';
        break;
    }

    return result;
  }

  toDataForm() {
    const {
      idOutput,
      parentId,
      name,
      description,
      intentType,
      groupId,
      sendUser,
      attempts,
      inputs,
      outputs,
      linked,
      dataBlockly,
      listType,
      rocketChat,
      messageType,
      varValue,
      flag,
      saveVarValue,
      entryName,
      metadata,
      block_id,
      apiInfo,
      apiReturnCodeTreatment,
      tags,
      audioVoice,
      carouselItems,
      bubbleInfo,
    } = this;

    return {
      idOutput,
      parentId,
      name,
      description,
      intentType,
      groupId,
      sendUser,
      attempts,
      inputs,
      outputs,
      linked,
      dataBlockly,
      listType,
      rocketChat,
      messageType,
      varValue,
      flag,
      saveVarValue,
      entryName,
      metadata,
      block_id,
      apiInfo,
      apiReturnCodeTreatment,
      tags,
      audioVoice,
      carouselItems,
      bubbleInfo
    };
  }
}

export default DataFormModel;
