import EIntentType from 'enums/EIntentType';
import { DataFlow, ItemDataFlow } from 'models/DataFlow';
import { IEntity } from 'models/Entity';
import { IFlow } from 'services/BotService/types';
import {
  ABTestTreatment,
  AiBlockTreatment,
  CarouselTreatment,
  GetDocumentLogic,
  OptionsBlockTreatment,
  QuestionBlockTreatment,
  SimpleCarouselTreatment,
  getCompiledPayload,
} from './BlocksTreatments';

let lastBlockId = 0;

const compileFlow = (flows: any[], entitiesResult: IEntity[]) => {
  const flexGroups: any[] = flows.filter((flow) =>
    flow.groupName.startsWith('flex')
  );
  flows.map((item) => preCompile(item, entitiesResult, flexGroups));
};

const preCompile = (data: IFlow, entities: IEntity[], flexGroups: any[]) => {
  const blocks = data.drawflow.drawflow.Home.data;
  const blockCopy = blocks;
  const botName = data.bot;
  const blocksKeys = Object.keys(blocks);
  lastBlockId = Number(blocksKeys[blocksKeys.length - 1]);

  Object.keys(blockCopy).forEach(function (block) {
    const blockObj = blocks[Number(block)];
    const blockData = blockObj.data;
    let outputBlock = 0;
    if (blockData) {
      if (
        [
          EIntentType.SendDocument,
          EIntentType.Carousel,
          EIntentType.SendMessage,
          EIntentType.OptionsBlock,
          EIntentType.SimpleQuestion,
          EIntentType.OpenHTML,
          EIntentType.ABTest,
          EIntentType.NLU,
          EIntentType.AI123,
          EIntentType.FlexBlockExit,
          EIntentType.FlexBlock,
          EIntentType.MetadataBlock,
        ].includes(blockData.intentType)
      ) {
        if (blockObj.outputs) {
          const block_output = blockObj.outputs;
          if (block_output.output_1) {
            if (
              block_output.output_1.connections &&
              block_output.output_1.connections[0] &&
              block_output.output_1.connections[0].node
            ) {
              outputBlock = block_output.output_1.connections[0].node;
            }
          }
        }
        blockChanger(
          botName,
          blocks,
          blockObj,
          outputBlock,
          lastBlockId,
          entities,
          flexGroups
        );
      }
    }
  });
};

const blockChanger = (
  botName: string,
  blocks: DataFlow,
  block: ItemDataFlow,
  to_block: number,
  lastBlock: number,
  entities: IEntity[],
  flexGroups: any[]
) => {
  switch (Number(block.data.intentType)) {
    case EIntentType.SimpleQuestion:
      const simpleQuestionFallbackBlock = {} as ItemDataFlow;

      if (block.data && block.data.outputs) {
        const fallBackBlock =
          block.data.outputs[block.data?.outputs.length - 1];
        simpleQuestionFallbackBlock.name =
          fallBackBlock?.nameIntent || block.data.name;

        simpleQuestionFallbackBlock.id =
          Number(block.data.outputs[block.data?.outputs.length - 1].outputid) ||
          0;
      }

      const inputBlock = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          inputs: { variables: [{ questions: [''] }] },
          intentType: 3,
          groupId: block.data.groupId,
          name: `${block.data.name}-input`,
        },
      } as ItemDataFlow;
      lastBlockId = lastBlock + 1;
      blocks[lastBlockId] = inputBlock;

      const logicBlock = {
        id: lastBlock + 2,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: block.data.groupId,
          name: `${block.data.name}-logica`,
        },
      } as ItemDataFlow;
      lastBlockId = lastBlock + 2;
      blocks[lastBlockId] = logicBlock;

      if (
        block.data.dataBlockly &&
        block.data.inputs &&
        block.data.inputs.variables[0]
      ) {
        block.data.dataBlockly.payload = ``;
        block.data.dataBlockly.compiledPayload = `event('${block.data.groupId}_${inputBlock.data.name}');\n`;

        const questions = block.data.inputs.variables[0].questions
          .filter((q) => q !== '')
          .map((q) => q.replaceAll('{{', '`${').replaceAll('}}', '}`'));

        const fixedVarValue =
          block.data.varValue
            ?.replaceAll('vars.', '')
            .replaceAll('user.', '') || '';

        if (inputBlock.data.inputs) {
          inputBlock.data.inputs.variables[0] = {
            name: `${fixedVarValue}`,
            questions,
            required: true,
            vartype: '@sys.any',
            defaultValue: '',
            isList: false,
            value: `$${fixedVarValue}`,
          };
        }
      }

      if (logicBlock.data.dataBlockly) {
        const outputBlockId = to_block !== 0 ? to_block : block.id;

        logicBlock.data.dataBlockly.compiledPayload = `				
					var input;\n
					var resp;\n
					vars.contagem = vars.contagem || 0;\n
				`;

        logicBlock.data.dataBlockly.compiledPayload += getCompiledPayload(
          blocks,
          block,
          inputBlock,
          outputBlockId,
          simpleQuestionFallbackBlock.name
        );

        logicBlock.data.dataBlockly.compiledPayload =
          logicBlock.data.dataBlockly.compiledPayload.replaceAll('\t', '');
      }

      inputBlock.data.dataBlockly = {
        lastVersion: 1,
        payload: '',
        xml: '',
        compiledPayload: `event('${block.data.groupId}_${logicBlock.data.name}')`,
      };

      blocks[lastBlockId] = logicBlock;
      break;

    case EIntentType.SendDocument:
      lastBlockId = GetDocumentLogic(blocks, block, lastBlock, botName);
      break;

    case EIntentType.OptionsBlock:
      lastBlockId = OptionsBlockTreatment(blocks, block, lastBlock, entities);
      break;

    case EIntentType.Carousel:
      lastBlockId = CarouselTreatment(blocks, block, lastBlock);
      break;

    case EIntentType.SendMessage:
      if (block.data.dataBlockly) {
        block.data.dataBlockly.payload += `// #PARSER#CONNECTOR#Saída#`;
      }
      break;

    case EIntentType.OpenHTML:
      if (block.data.dataBlockly) {
        const destinationBlock = blocks[to_block];
        const destinationBlockName = destinationBlock
          ? destinationBlock.data.name
          : '';
        block.data.dataBlockly.payload = '';
        block.data.dataBlockly.compiledPayload = `
				if (user.appClient == "Web" || user.appClient == "App"){
					msg({
						"type": "iframe",
						"items": \`${block.data.sendUser?.url || block.data.sendUser?.message}\` ,
						"onClose": '${block.data.groupId}_${destinationBlockName}',
						"userId": user.id
					}, 'payload');
					} else {
						msg(\`${block.data.sendUser?.url || block.data.sendUser?.message}\`);
						event('${block.data.groupId}_${destinationBlockName}');
					}
				`.replaceAll('\t', '');
      }
      break;

    case EIntentType.ABTest:
      ABTestTreatment(block);
      break;

    case EIntentType.NLU:
      if (block.data.inputs) {
        block.data.inputs.userSentences = block.data.inputs?.userSentences?.map(
          (sentence) => sentence.replaceAll(/[\r\n\t]/g, '')
        );
      }
      break;

    case EIntentType.MetadataBlock:
      switch (block.data.metadata?.type) {
        case 'Message':
        case 'Entry': {
          if (block.data.sendUser && block.data.sendUser.message) {
            block.data.sendUser.message = block.data.sendUser?.message.replace(
              /'/g,
              "\\'"
            );
          }

          const messageOutputs = block.data.outputs || [];

          let ttsPayload = '';
          if (block.data.messageType === 'tts') {
            ttsPayload = `msg('${block.data.sendUser?.message}', type = 'tts', subtitle = '', null, '${block.data.audioVoice}')\n`;
          }

          if (messageOutputs.length === 0) return;

          const messageDestinationBlock = messageOutputs[0].outputid
            ? blocks[Number(messageOutputs[0].outputid)]
            : undefined;

          let compiledPayload = ttsPayload;
          compiledPayload += messageDestinationBlock
            ? `event('${block.data.groupId}_${messageDestinationBlock.data.name}')`
            : '';

          block.data.dataBlockly = {
            ...block.data.dataBlockly,
            payload: '',
            compiledPayload,
            lastVersion: 0,
            xml: block.data.dataBlockly?.xml || '',
          };
          break;
        }

        case 'Question':
          lastBlockId = QuestionBlockTreatment(block, blocks, lastBlock);
          break;

        case 'Multiple':
          lastBlockId = OptionsBlockTreatment(
            blocks,
            block,
            lastBlock,
            entities
          );
          break;

        case 'Carousel':
          lastBlockId = CarouselTreatment(blocks, block, lastBlock);
          break;

        case 'SimpleCarousel':
          SimpleCarouselTreatment(block);
          break;

        case 'ToAnotherBlock':
          const toAnotherBlockDestination = `${block.data.linked?.groupId}_${block.data.linked?.intentId}`;
          if (block.data.dataBlockly) {
            block.data.dataBlockly = {
              payload: '',
              lastVersion: 0,
              xml: '',
              compiledPayload: `event('${toAnotherBlockDestination}')`,
            };
          }
          break;

        case 'GetDocument':
          lastBlockId = GetDocumentLogic(blocks, block, lastBlock, botName);
          break;

        case 'ABTest':
          ABTestTreatment(block);
          break;

        case 'ValidateCPF':
        case 'ValidateCNPJ':
          if (block.data.dataBlockly && block.data.varValue) {
            block.data.dataBlockly.payload += `\n${block.data.varValue} = user.input;`;
          }
          break;

        case 'Conditional':
          if (block.data.dataBlockly) {
            const messageOutputs = block.data.outputs ?? [];

            messageOutputs.forEach((output) => {
              const destinationBlock = blocks[Number(output?.outputid)];
              let conditionsCode = '';
              if (destinationBlock) {
                if (output.ifCondition) {
                  conditionsCode += `
									if (${output.ifCondition}) {
										event('${block.data.groupId}_${destinationBlock.data.name}'); 
										return;
									} 
								`;
                } else {
                  conditionsCode += `
									event('${block.data.groupId}_${destinationBlock.data.name}'); 
									return;
									`;
                }
              }
              if (block.data.dataBlockly) {
                block.data.dataBlockly.compiledPayload += conditionsCode;
                block.data.dataBlockly.compiledPayload =
                  block.data.dataBlockly.compiledPayload.replaceAll('\t', '');
              }
            });
          }
          break;

        case 'Api': {
          if (!block.data.apiInfo) return;

          const {
            body,
            headers: blockHeaders,
            params: blockParams,
            query: blockQuery,
            method,
            url: blockUrl,
          } = block.data.apiInfo;
          if (!method || !blockUrl || !block.data.outputs) return;

          const apiReturnBlock = {
            id: lastBlock + 1,
            data: {
              dataBlockly: {
                compiledPayload: '',
                lastVersion: 0,
                payload: '',
                xml: '',
              },
              intentType: 120,
              groupId: block.data.groupId,
              name: `${block.data.name}-api-return`,
            },
          } as ItemDataFlow;

          const [agent, stage] = botName.split('-');

          let apiReturnBlockCompiledPayload = `
					    const ${block.data.varValue} = JSON.parse(decodeURIComponent("$parameters"));

							${block.data.apiReturnCodeTreatment}
							`;

          block.data.outputs.forEach((output) => {
            const destinationBlock = blocks[Number(output?.outputid)];
            if (destinationBlock && output.ifCondition) {
              apiReturnBlockCompiledPayload += `
							if (${output.ifCondition}) { 
								${output.conditionCode}
								event('${block.data.groupId}_${destinationBlock.data.name}'); 
								return;
							}`;
            }
          });

          const othersOutput =
            block.data.outputs[block.data.outputs.length - 1];
          const apiOthersOutputId = othersOutput?.outputid;

          const apiOthersOutputBlock = blocks[Number(apiOthersOutputId)];

          if (apiOthersOutputBlock) {
            apiReturnBlockCompiledPayload += `
						event('${block.data.groupId}_${apiOthersOutputBlock.data.name}'); 
						`;
          }

          apiReturnBlock.data.dataBlockly = {
            compiledPayload: apiReturnBlockCompiledPayload.replaceAll('\t', ''),
            lastVersion: 0,
            payload: '',
            xml: '',
          };

          lastBlockId = lastBlock + 1;
          blocks[lastBlock + 1] = apiReturnBlock;
          const regex = /{{(.*?)}}/g;

          let headers = '{';
          blockHeaders.forEach((header, index) => {
            headers += `"${[header.key]}": ${header.value.match(regex)?.[0]
                ? header.value.replace(
                  regex,
                  (_, capture) => `\`\${${capture}}\``
                )
                : `"${header.value}"`
              }`;

            if (index !== blockHeaders.length) headers += ',';
          });
          headers += '}';

          let newUrl = blockUrl;

          const paramsAndQueries = [...blockParams, ...blockQuery];

          paramsAndQueries.forEach((param, index) => {
            if (index === 0) newUrl += `?${param.key}=${param.value}`;
            else newUrl += `&${param.key}=${param.value}`;
          });

          newUrl = newUrl.replace(regex, (_, capture) => `\${${capture}}`);

          const bodyRegex = /\{\{([^}]+)\}\}/g;

          // eslint-disable-next-line no-template-curly-in-string
          const fixedBody = body.replace(bodyRegex, '`$${$1}`').trim();

          const fBody = fixedBody
            ? '{' + fixedBody.concat('}')
            : JSON.stringify('');

          const apiRequest = `
						const response = await request('POST', 'https://n8n-prd-webhook.fintalk.io/webhook/fintalk/async', 
							{	 
								"url": \`${newUrl}\`,
								"body": ${fBody}, 
								"headers": ${headers},
								"blockName": '${block.data.groupId}_${apiReturnBlock.data.name}',
								"agent": '${agent}',
								"stage": '${stage}',
								"userId": user.id,
								"method": '${method.toUpperCase()}'
							}
						);
					`.replaceAll('\t', '');

          block.data.dataBlockly = {
            ...block.data.dataBlockly,
            payload: '',
            compiledPayload: `${apiRequest}`,
            lastVersion: 0,
            xml: '',
          };

          break;
        }

        case '123AI':

          lastBlockId = AiBlockTreatment(
            botName,
            blocks,
            block,
            lastBlock,
            entities
          );
          break;

        case 'Bubble': {
          if (
            !block.data.bubbleInfo || 
            !block.data.bubbleInfo.bubbleId || 
            !block.data.bubbleInfo.bubbleVersion
          ) return;

          const { bubbleId, bubbleVersion } = block.data.bubbleInfo;

          let compiledPayload = `
            await bubbleStart('${bubbleId}', ${bubbleVersion})
          `

          block.data.dataBlockly = {
            ...block.data.dataBlockly,
            payload: '',
            compiledPayload,
            lastVersion: 0,
            xml: '',
          };
        }
      }

      break;

    case EIntentType.FlexBlock:
      const flexBlockRelatedGroup = flexGroups.find(
        (group) => group.groupName === `flex-${block.data.name}`
      );
      if (!flexBlockRelatedGroup) break;
      const flexBlockRelatedGroupBlocks =
        flexBlockRelatedGroup.drawflow.drawflow.Home.data;
      const flexGroupBlocksKeys = Object.keys(flexBlockRelatedGroupBlocks);

      let entryBlockInFlexGroup: ItemDataFlow = {} as ItemDataFlow;
      const exitBlocks: ItemDataFlow[] = [];

      lastBlockId = lastBlock;

      for (const key in flexGroupBlocksKeys) {
        const currentBlock: ItemDataFlow =
          flexBlockRelatedGroupBlocks[flexGroupBlocksKeys[key]];

        if (currentBlock.data.intentType === EIntentType.FlexBlockEntry) {
          entryBlockInFlexGroup = currentBlock;
        }

        if (currentBlock.data.intentType === EIntentType.FlexBlockExit) {
          exitBlocks.push(currentBlock);
        }
      }

      let flexBlockCompiledPayload = '';

      exitBlocks.forEach((exitBlock, index) => {
        if (block.data.outputs && block.data.outputs[index]) {
          exitBlock.data.dataBlockly = {
            ...exitBlock.data.dataBlockly,
            lastVersion: exitBlock.data.dataBlockly?.lastVersion || 0,
            payload: '',
            xml: '',
            compiledPayload: `event('${block.data.groupId}_${block.data.outputs[index].nameIntent}')`,
          };

          flexBlockCompiledPayload += `
					vars.__output${index}_event = '${block.data.groupId}_${block.data.outputs[index].nameIntent}'
					`;
        }
      });
      flexBlockCompiledPayload += `event('${flexBlockRelatedGroup.groupName}_${entryBlockInFlexGroup.data.name}')`;

      block.data.dataBlockly = {
        xml: block.data.dataBlockly?.xml || '',
        payload: '',
        lastVersion: block.data.dataBlockly?.lastVersion || 1,
        compiledPayload: flexBlockCompiledPayload,
      };
      break;

    case EIntentType.FlexBlockExit:
      const flexBlockGroup = flexGroups.find(
        (group) => group.groupName === block.data.groupId
      );
      if (!flexBlockGroup) break;
      const flexBlockRelatedlocks = flexBlockGroup.drawflow.drawflow.Home.data;
      const flexGroupKeys = Object.keys(flexBlockRelatedlocks);

      const exits: ItemDataFlow[] = [];

      for (const key in flexGroupKeys) {
        const currentBlock: ItemDataFlow =
          flexBlockRelatedlocks[flexGroupKeys[key]];

        if (currentBlock.data.intentType === EIntentType.FlexBlockExit) {
          exits.push(currentBlock);
        }
      }

      exits.forEach((exit, index) => {
        if (exit.data.name === block.data.name) {
          block.data.dataBlockly = {
            xml: block.data.dataBlockly?.xml || '',
            payload: '',
            lastVersion: block.data.dataBlockly?.lastVersion || 1,
            compiledPayload: `event(vars.__output${index}_event)`,
          };
          return;
        }
      });

      break;

    case EIntentType.AI123:
      const groupName = block.data.groupId;

      const falaGptInputBlock = {
        id: lastBlock + 1,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          outputs: block.data.outputs,
          intentType: 211,
          groupId: groupName,
          name: `${block.data.name}-recognition`,
        },
      } as ItemDataFlow;

      const falaGptLogicBlock = {
        id: lastBlock + 2,
        data: {
          dataBlockly: {
            compiledPayload: '',
            lastVersion: 0,
            payload: '',
            xml: '',
          },
          intentType: 120,
          groupId: groupName,
          name: `${block.data.name}-logic`,
        },
      } as ItemDataFlow;

      const falaGptOutputIndex = block.data.outputs!.findIndex(
        (output) => output.title === '123AI'
      );

      if (falaGptOutputIndex !== -1) {
        falaGptInputBlock.data.outputs![falaGptOutputIndex].nameIntent =
          falaGptLogicBlock.data.name;

        falaGptInputBlock.data.outputs![falaGptOutputIndex].title = 'Outros';
      }

      blocks[lastBlock + 1] = falaGptInputBlock;
      blocks[lastBlock + 2] = falaGptLogicBlock;

      if (block.data.outputs) {
        const synonymArray: any[] = [];
        block.data.outputs?.forEach((output) => {
          return synonymArray.push(
            output.userSentences?.filter((s) => s !== '')
          );
        });

        synonymArray.forEach((sArr) => {
          if (sArr) {
            sArr.forEach((s: string, index: number) => {
              if (s.substring(0, 1) === '@') {
                const foundEntity = entities.find((e) => `@${e.name}` === s);
                if (foundEntity) {
                  foundEntity.values[0].synonyms.forEach((v) => {
                    sArr.push(v);
                  });
                }
                sArr.splice(index, 1);
              }
            });
          }
        });

        const chipsTitles = block.data.outputs
          ?.map((output) => (output.isChip ? output.title : ''))
          .filter((item) => item !== 'Outros' && item !== '');

        let chipsString = '[';

        chipsTitles?.forEach((chip, index) => {
          chipsString += `'${chip}'`;
          if (index < chipsTitles.length - 1) {
            chipsString += ',';
          }
        });

        chipsString += ']';

        const chipsEvent =
          chipsString !== '[]' ? `msg(${chipsString}, 'chips');\n` : '';

        block.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
							${chipsEvent};
							setLocalCapture(true, '${block.data.groupId}_${falaGptInputBlock.data.name}');
						`,
          payload: '',
          xml: '',
        };

        block.data.intentType = 100;

        falaGptInputBlock.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: `
					  setLocalCapture(false)
        	`,
          payload: '',
          xml: '',
        };

        const logicCompiledPayload = `
				try {
					const response = await chatGPTQuestion(user.id, "${block.data.outputs[0].falaGPTContext}", user.input);					
					if (response) {
						vars.respLonga = response;
						msg(vars.respLonga)
					}
					else {
						msg('Tive um problema para encontrar uma resposta, tente novamente.', 'text');
					} 
					event('${groupName}_${falaGptInputBlock.data.name}')
				}
				catch (e) {
					msg('Tive um problema para encontrar uma resposta, tente novamente.', 'text');
					event('${groupName}_${falaGptInputBlock.data.name}')
				}
				`;

        falaGptLogicBlock.data.dataBlockly = {
          lastVersion: 0,
          compiledPayload: logicCompiledPayload.replaceAll('\t', ''),
          payload: '',
          xml: '',
        };
      }
      break;
  }
};

export default compileFlow;
